<template>
  <form>
    <div class="row d-flex justify-content-center">
      <div class="col-md-12">
        <ValidationObserver ref="card">
          <card title="Create Recipes for New Venue Drinks">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-5">
                  <ValidationProvider
                    name="customer"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      name="Customer"
                      :error="failed ? 'The Customer field is required' : null"
                      :hasSuccess="passed"
                      label="Customer Name"
                    >
                      <el-select
                        class="select-primary"
                        v-model="customer"
                        placeholder="Customer Name"
                        filterable
                        clearable
                        @change="getVenues(customer.id)"
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in customers"
                          :key="item.ID"
                          :label="item.NAME"
                          :value="item.ID"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </ValidationProvider>
                </div>

                <div class="col-md-5">
                  <ValidationProvider
                    name="venue"
                    rules="required"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      name="Venue"
                      :error="failed ? 'The Venue field is required' : null"
                      :hasSuccess="passed"
                      label="Venue"
                    >
                      <el-select
                        class="select-primary"
                        v-model="venue"
                        placeholder="Choose Venue"
                        @change="getVenueDrinks()"
                        filterable
                        clearable
                      >
                        <el-option
                          class="select-primary"
                          v-for="item in venues"
                          :key="item.ID"
                          :label="item.NAME"
                          :value="item.ID"
                        >
                        </el-option>
                      </el-select>
                    </fg-input>
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </card>
        </ValidationObserver>
        <card title="New Venue Drinks">
          <div class="col-md-12">
            <div class="row">
              <pg-table
                :tableColumns="VenueDrinkPgTable.tableColumns"
                :tableData="VenueDrinkPgTable.tableData"
                :propsToSearch="VenueDrinkPgTable.propsToSearch"
                :handleCurrentChange="VenueDrinkPgTable.handleCurrentChange"
                :handleTextChange="VenueDrinkPgTable.handleTextChange"
                :handleSelectionChange="VenueDrinkPgTable.handleSelectionChange"
                :paginated="true"
                :searchable="true"
                :preSelected="true"
                :withFieldsFiltering="true"
              >
              </pg-table>
            </div>

            <div class="text-right">
              <button
                type="button"
                class="btn btn-info btn-fill btn-wd"
                :disabled="venue == ''"
                v-on:click="showNewDrinksCard"
              >
                Manually Add Drinks</button
              >&nbsp;
            </div>
          </div>
        </card>
        <!-- end card -->
        <!-- Adding new drinks card -->
        <card
          v-if="newDrinkTableActive"
          title="Manually Add Drinks"
          :withClosebtn="true"
          :closeFunction="closeAddingNewDrinks"
        >
          <div class="col-md-12">
            <div class="row">
              <pg-table
                :tableColumns="NewVenueDrinkPgTable.tableColumns"
                :tableData="NewVenueDrinkPgTable.tableData"
                :withActions="true"
                :withAddingRows="true"
                :AddbuttonText="'New Drink'"
              >
              </pg-table>
            </div>

            <div class="text-right">
              <button
                type="button"
                class="btn btn-success btn-fill btn-wd"
                v-on:click="addNewDrink"
              >
                Add Drinks</button
              >&nbsp;
            </div>
            <span class="error" v-if="newDrinks_errorMsg != ''">
              {{ newDrinks_errorMsg }}
            </span>
          </div>
        </card>
        <!-- end card -->
        <!-- Add ingredients card -->
        <card
          v-if="selectedDrink != null"
          :title="selectedDrink.NAME + ' ' + 'Recipe'"
        >
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-4">
                  <fg-input
                    name="pluCode"
                    placeholder="PLU Code"
                    label="Plu Code"
                    v-model="selectedDrink.PLU_CODE"
                    :disabled="true"
                    ddon-left-icon="nc-icon nc-single-02"
                  >
                  </fg-input>
                </div>
              </div>
              <pg-table
                :tableColumns="IngredientsPgTable.tableColumns"
                :tableData="selectedDrink.Ingredients"
                :handleTextChange="IngredientsPgTable.handleTextChange"
                :handleSelectionChange="IngredientsPgTable.handleSelection"
                :withActions="true"
                :withAddingRows="true"
                :AddbuttonText="'New Ingredient'"
                :VENUE_DRINK_ID="selectedDrink.ID"
                @on-deleteRow="deleteIngredientRow"
              >
              </pg-table>
            </div>
          </div>
        </card>
        <!-- end card -->
        <div class="text-right">
          <button
            type="submit"
            class="btn btn-info btn-fill btn-wd"
            @click.prevent="updateDrinks"
            :disabled="!changesMade"
          >
            Save Updates
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { Select, Option } from "element-ui";
import PgTable from "src/pages/Dashboard/Tables/PaginatedTables.vue";
import { initAPI } from "src/gateway/api-instance.js";

extend("required", required);
export default {
  components: {
    PgTable,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      VenueDrinkPgTable: {
        tableColumns: [
          {
            prop: "DRINK_STATUS",
            label: "Monitor",
            minWidth: 80,
            visible: true,
            editable: true,
            editType: "select",
            selectList: [
              { value: "monitored", label: "Monitored" },
              { value: "unmonitored", label: "Un-Monitored" },
              { value: "pending", label: "Pending" }
            ]
          },
          {
            prop: "ID",
            label: "ID",
            visible: true,
            minWidth: 45
          },
          {
            prop: "NAME",
            label: "Drink Name",
            minWidth: 120,
            visible: true
          },
          {
            prop: "PLU_CODE",
            label: "PLU",
            visible: true,
            minWidth: 50
          },
          {
            prop: "TYPE",
            label: "Type",
            minWidth: 75,
            visible: true,
            editable: true,
            editType: "select",
            selectList: [
              { value: "Food", label: "Food" },
              { value: "Liquor", label: "Liquor" },
              { value: "Beer", label: "Beer" },
              { value: "Wine", label: "Wine" },
              { value: "Seltzer", label: "Seltzer" },
              { value: "Hard Cider", label: "Hard Cider" },
              { value: "Other", label: "Other" }
            ]
          },
          {
            prop: "DRINK_CATEGORY",
            label: "Drink Category",
            minWidth: 80,
            visible: true,
            editable: true,
            editType: "select",
            selectList: []
          },
          {
            prop: "VERSION",
            label: "Version",
            minWidth: 60,
            visible: true
          },
          {
            prop: "REF_DRINK_ID",
            label: "Master Drink Ref",
            minWidth: 90,
            visible: true,
            editable: true,
            editType: "select",
            selectList: []
          },
          {
            prop: "CREATED_BY",
            label: "Created by",
            minWidth: 80,
            visible: true
          },
          {
            prop: "CREATED_ON",
            label: "Created on",
            minWidth: 80,
            visible: true
          }
        ],
        tableData: [],
        propsToSearch: [
          "NAME",
          "ID",
          "REF_DRINK_ID",
          "VERSION",
          "TYPE",
          "DRINK_CATEGORY",
          "PLU_CODE",
          "CREATED_ON",
          "CREATED_BY",
          "DRINK_STATUS"
        ],
        handleCurrentChange: this.addIngredients,
        handleSelectionChange: this.changeMonitorStatus,
        handleTextChange: this.handleDrinkChange
      },
      IngredientsPgTable: {
        tableColumns: [
          {
            prop: "VENUE_LIQUOR_ID",
            label: "Ingredient",
            minWidth: 95,
            editable: true,
            visible: true,
            editType: "select",
            selectList: []
          },
          {
            prop: "AMOUNT",
            label: "Amount (Ounces)",
            minWidth: 35,
            visible: true,
            editable: true,
            editType: "input",
            textType: "Number"
          }
        ],
        handleSelection: this.handleIngredientSelectionChange
      },
      NewVenueDrinkPgTable: {
        tableColumns: [
          {
            prop: "DRINK_STATUS",
            label: "Monitor",
            minWidth: 80,
            visible: true,
            editable: true,
            editType: "select",
            selectList: [
              { value: "monitored", label: "Monitored" },
              { value: "unmonitored", label: "Un-Monitored" },
              { value: "pending", label: "Pending" }
            ]
          },
          {
            prop: "NAME",
            label: "Drink Name",
            minWidth: 100,
            visible: true,
            editable: true,
            editType: "input",
            textType: "Text"
          },
          {
            prop: "PLU_CODE",
            label: "PLU",
            minWidth: 80,
            visible: true,
            editable: true,
            editType: "input",
            textType: "Number"
          },
          {
            prop: "TYPE",
            label: "Type",
            minWidth: 75,
            visible: true,
            editable: true,
            editType: "select",
            selectList: [
              { value: "Food", label: "Food" },
              { value: "Liquor", label: "Liquor" },
              { value: "Beer", label: "Beer" },
              { value: "Wine", label: "Wine" },
              { value: "Seltzer", label: "Seltzer" },
              { value: "Hard Cider", label: "Hard Cider" },
              { value: "Other", label: "Other" }
            ]
          },
          {
            prop: "DRINK_CATEGORY",
            label: "Drink Category",
            minWidth: 80,
            visible: true,
            editable: true,
            editType: "select",
            selectList: []
          },
          {
            prop: "REF_DRINK_ID",
            label: "Master Drink Ref",
            minWidth: 90,
            visible: true,
            editable: true,
            editType: "select",
            selectList: []
          }
        ],
        tableData: []
      },
      customers: [],
      customer: "",
      venues: [],
      venue: "",
      selectedDrink: null,
      masterDrinkList: [],
      changesMade: false,
      newButtonText: "Add New Drink",
      newDrinkTableActive: false,
      newDrinks_errorMsg: ""
    };
  },
  computed: {
    // This is a computed variable, that it's value is recomputed every time it's used throughout the component.
    Instance() {
      return initAPI(this.$cookies.get("Token"), process.env.VUE_APP_API_URL);
    }
  },
  /*
    - Called when the route that renders this component is about to be navigated away from.
    - Has access to `this` component instance.
  */
  beforeRouteLeave(to, from, next) {
    // If the form is dirty and the user did not confirm leave,
    // prevent losing unsaved changes by canceling navigation
    if (this.confirmStayInDirtyForm()) {
      next(false);
    } else {
      // Navigate to next view
      next();
    }
  },
  methods: {
    /*
      - To confirm that the user wants to leave and discard the changes.
    */
    confirmLeave() {
      return window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      );
    },
    /*
      - To confirm that the user wants to stay and save the changes.
    */
    confirmStayInDirtyForm() {
      return this.changesMade && !this.confirmLeave();
    },
    /*
      - called when the user tries to navigate away from the page
    */
    beforeWindowUnload(e) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = "";
      }
    },
    /*
      - Called when the "Manually Add Drinks" button is clicked.
      - Sets the flag to show the card for manually adding drinks in the database.
      - Clers the error message variable, before the start.
    */
    showNewDrinksCard() {
      this.newDrinks_errorMsg = "";
      this.newDrinkTableActive = true;
    },
    /*
      - Called when a user decides to add a new drink to the database.
      - Validates all the entered data in the "Manually Add Drinks" card, by insuring that:
        - There's no empty attributes.
        - There's no attributes with spaces only.
    */
    validateAddedDrink(element) {
      if (
        typeof element.NAME != "undefined" &&
        typeof element.DRINK_STATUS != "undefined" &&
        typeof element.PLU_CODE != "undefined" &&
        typeof element.TYPE != "undefined" &&
        typeof element.REF_DRINK_ID != "undefined" &&
        typeof element.DRINK_CATEGORY != "undefined"
      ) {
        if (
          element.NAME.trim() != "" &&
          element.DRINK_STATUS.trim() != "" &&
          element.TYPE.trim() != "" &&
          element.DRINK_CATEGORY.trim() != ""
        ) {
          return true;
        }
      }
      return false;
    },
    /*
      - If the added drink is valid, then this function is called.
      - Prepares the drink object that'll be sent to the backend by:
        - adding the refrance drink version.
        - adding the name of the logged in user in the "CREATED_BY" attribute.
        - adding the venue_id that this drink will be added to.
        - setting the drink version with 1.
    */
    prepareNewDrink(element, index) {
      let vm = this;
      let ind = this.masterDrinkList.findIndex(
        item => item.ID === element.REF_DRINK_ID
      );
      this.$set(
        this.NewVenueDrinkPgTable.tableData[index],
        "REF_DRINK_VERSION",
        this.masterDrinkList[ind].VERSION
      );
      this.$set(
        this.NewVenueDrinkPgTable.tableData[index],
        "CREATED_BY",
        vm.$cookies.get("username")
      );
      this.$set(
        this.NewVenueDrinkPgTable.tableData[index],
        "VENUE_ID",
        this.venue
      );
      this.$set(this.NewVenueDrinkPgTable.tableData[index], "VERSION", "1");
    },
    /*
      - Called when the user clicks the "Add Drinks" button in the "Manually Add Drinks" card.
      - Loops through all the intered drinks and for each drink:
        - validates the drink object.
        - if the object is valid, prepares the drink object to send it to the backend.
        - pushes the prepare object to a list of the drinks that'll be sent to the backend.
        - removes the validated drink from the card list, so that the un-valid drinks will remain in the UI,
          and the user can alter them, instead of reentring them.
        - In case of an un-valid drink, an error message is shown to alert the user.
      - Calls the POST API "new/plu", with the list of valid drinks to be saved in the database, to the backend.
    */
    addNewDrink() {
      let saved_drinks = [];
      this.NewVenueDrinkPgTable.tableData.forEach((element, index) => {
        this.newDrinks_errorMsg = "";
        if (this.validateAddedDrink(element)) {
          this.prepareNewDrink(element, index);
          saved_drinks.push(this.NewVenueDrinkPgTable.tableData[index]);
          this.NewVenueDrinkPgTable.tableData.splice(index, 1);
        } else {
          this.newDrinks_errorMsg =
            "Please make sure that you've entered valid values (not just spaces) in all of the fields, before attempting to save the new drinks.";
        }
      });

      // tweeking the object for the backend
      let obj = {};
      this.$set(obj, "new_drinks", saved_drinks);

      this.Instance.post("new/plu", obj)
        .then(function(response) {
          console.log(response);
        })
        .catch(function(error) {
          console.error(error);
        });
    },
    /*
      - Called when the destroy button in the "Manually Add Drinks" card, is called.
      - empty's the card list, and un-sets the card active flag, to hide it in the UI page.
    */
    closeAddingNewDrinks() {
      this.NewVenueDrinkPgTable.tableData = [];
      this.newDrinkTableActive = false;
    },
    /*
     - Calls a GET API to get a list of drink ingrdients from the simplica database with the drink Id.
    */
    getDrinkIngredients(row) {
      return this.Instance.get("/simplica/drinks_ing?drinkId=" + row.ID).then(
        response => {
          return response;
        }
      );
    },
    /*
      - Called when the user clicks on any row in the drinks table.
      - Checks if the drink's monitor attribute is true, we can add ingredients to it, or if it's status is pending.
      - When the drink status is confirmed, the list of ingredients is intialized, in the drink object of the clicked row.
      - The selectedDrink variable is set with the clicked row drink, for the paginated-table tag.
    */
    addIngredients(row) {
      this.selectedDrink = null;
      let vm = this;
      if (row.MONITOR || row.DRINK_STATUS == "pending") {
        let ind = this.VenueDrinkPgTable.tableData.findIndex(
          item => item.ID === row.ID
        );
        if (this.VenueDrinkPgTable.tableData[ind].Ingredients == null) {
          this.getDrinkIngredients(row).then(function(response) {
            if(response.data != null && response.data.length > 0){
              vm.$set(
                vm.VenueDrinkPgTable.tableData[ind],
                "Ingredients",
                response.data
              );
            }
            else{
              vm.$set(vm.VenueDrinkPgTable.tableData[ind], "Ingredients", []);
            }
          });
        }
        vm.selectedDrink = vm.VenueDrinkPgTable.tableData[ind];
      }
    },
    /*
      - Called when the user clickes the delete button in the actions cloumn in the paginated table component, of the drink ingredients.
      - The paginated table component delets the ingrendient for the drink object's list, and emits a trigger to this function.
      - This function's purpose, is to just set the flag of change, for the drink and the whole form.
    */
    deleteIngredientRow(row) {
      let index = this.VenueDrinkPgTable.tableData.findIndex(
        item => item.ID === row.VENUE_DRINK_ID
      );
      this.VenueDrinkPgTable.tableData[index].changed = true;
      this.changesMade = true;
    },
    /*
      - Called when the @change attribute in a drinks' table, drop down list is invoked.
      - in this case, what we care about is the monitor, drop-down list.
      - If the drink status's changed to 'unmonitored or 'pending', then it's monitor falg is set to false.
      - If the drink status's changed to 'monitored', then it's monitor falg is set to true.
      - The drink's flag and the overall changesMade flag, are set to true.
    */
    changeMonitorStatus(row) {
      let index = this.VenueDrinkPgTable.tableData.findIndex(
        item => item.ID === row.ID
      );
      if (
        this.VenueDrinkPgTable.tableData[index].DRINK_STATUS == "unmonitored" ||
        this.VenueDrinkPgTable.tableData[index].DRINK_STATUS == "pending"
      ) {
        this.VenueDrinkPgTable.tableData[index].MONITOR = false;
      } else {
        this.VenueDrinkPgTable.tableData[index].MONITOR = true;
      }
      this.VenueDrinkPgTable.tableData[index].changed = true;
      this.changesMade = true;
      this.selectedDrink = null;
    },
    /*
      - Called when an input is made to the input boxes in the drinks' table.
      - Validates if the call is from the drinks or the ingreient's tables, by differentiating between the ID column name.
        (ID for the drinks' table, and VENUE_DRINK_ID for the ingredients' table)
      - The drink's flag and the overall changesMade flag, are set to true.
    */
    handleDrinkChange(row) {
      let index;
      if (typeof row.ID != "undefined")
        index = this.VenueDrinkPgTable.tableData.findIndex(
          item => item.ID === row.ID
        );
      else {
        index = this.VenueDrinkPgTable.tableData.findIndex(
          item => item.ID === row.VENUE_DRINK_ID
        );
      }

      this.VenueDrinkPgTable.tableData[index].changed = true;
      this.changesMade = true;
    },
    /*
      - Called when the @change attribute in a ingredients' table, drop down list is invoked.
      - sets the ingredient VENUE_LIQUOR_VERSION with the chosen master liquor version.
      - The drink's flag and the overall changesMade flag, are set to true.
    */
    handleIngredientSelectionChange(row) {
      let index = this.VenueDrinkPgTable.tableData.findIndex(
        item => item.ID === row.VENUE_DRINK_ID
      );
      this.VenueDrinkPgTable.tableData[index].changed = true;

      index = this.masterLiquorList.findIndex(
        item => item.ID === row.VENUE_LIQUOR_ID
      );
      row.VENUE_LIQUOR_VERSION = this.masterLiquorList[index].VERSION;

      this.changesMade = true;
    },
    /*
      - Called when an input is made to the input boxes in the ingredients' table.
      - The drink's flag and the overall changesMade flag, are set to true.
    */
    handleIngredientChange(row) {
      let index = this.VenueDrinkPgTable.tableData.findIndex(
        item => item.ID === row.ID
      );
      this.VenueDrinkPgTable.tableData[index].changed = true;
      this.changesMade = true;
    },
    /*
      - Calls a GET API to get a list of drink categories from the simplica database.
      - fills the appropriate select lists with the retrieved data.
    */
    getDrinkCategories() {
      this.Instance.get("simplica/drink_categories/").then(response => {
        let templist = [];
        response.data.forEach((element, index) => {
          templist.push({
            value: response.data[index].NAME,
            label: response.data[index].NAME
          });
          this.VenueDrinkPgTable.tableColumns[5].selectList = templist;
          this.NewVenueDrinkPgTable.tableColumns[4].selectList = templist;
        });
      });
    },
    /*
      - Calls a GET API to get a list of master drinks from the simplica database.
      - fills the global master drink list and the appropriate select lists, with the retrieved data
    */
    getMsterDrinks() {
      let vm = this;
      this.Instance.get("simplica/master_drinks/").then(response => {
        vm.masterDrinkList = response.data;
        response.data.forEach((element, index) => {
          vm.VenueDrinkPgTable.tableColumns[7].selectList.push({
            value: element.ID,
            label: element.NAME
          });
          vm.NewVenueDrinkPgTable.tableColumns[5].selectList.push({
            value: element.ID,
            label: element.NAME
          });
        });
      });
    },
    /*
      - Calls a GET API to get a list of master liqours from the simplica database.
      - sets the liquor name to it's BRAND + ALCOHOL_NAME if it's found.
      - fills the appropriate select lists with the retrieved data.
    */
    getMasterLiqours() {
      let vm = this;
      this.Instance.get("simplica/master_liquors?venue_id=" + this.venue).then(
        response => {
          response.data.forEach((element, index) => {
            let name;
            if (response.data[index].ALCOHOL_NAME == null)
              name = response.data[index].BRAND;
            else
              name =
                response.data[index].BRAND +
                " - " +
                response.data[index].ALCOHOL_NAME;
                if(response.data[index].REMOVED == "F"){
                  vm.IngredientsPgTable.tableColumns[0].selectList.push({
                    value: response.data[index].ID,
                    label: name
                  });
                }
          });
          this.masterLiquorList = response.data.filter(item => {
            return item.REMOVED === "F";
          });
        }
      );
    },
    /*
      - Calls a GET API to get a list of venue drinks from the simplica database.
      - fills the venueDrinks list with the retrived data, and calls a function to append the pending drinks to it.
      - Calls the ge functions to get the drink categories, mater drinks and liqours.
    */
    getVenueDrinks() {
      this.getMsterDrinks();
      this.getDrinkCategories();
      let vm = this;
      this.Instance.get(
        "simplica/drinks?venueId=" + this.venue + "&version=0"
      ).then(response => {
        vm.VenueDrinkPgTable.tableData = response.data;
        vm.getPendingDrinks();
      });
      this.getMasterLiqours();
    },
    /*
      - Calls a GET API to get a list of pending drinks from the simplica database.
      - appends the retrieved data to the venueDrins list.
      - for each drink in the venue drink list:
        - intialize the changed flag with false.
        - intialize the ingreients object with null.
        - change the monitor attribute value from string to it's equivalent boolean value.
    */
    getPendingDrinks() {
      let vm = this;
      this.Instance.get("simplica/pending_drinks?venueId=" + this.venue).then(
        response => {
          response.data.forEach((element, index) => {
            vm.VenueDrinkPgTable.tableData.push(element);
          });

          vm.VenueDrinkPgTable.tableData.forEach((element, index) => {
            vm.$set(vm.VenueDrinkPgTable.tableData[index], "changed", false);
            vm.$set(vm.VenueDrinkPgTable.tableData[index], "Ingredients", null);
            if (vm.VenueDrinkPgTable.tableData[index].MONITOR == "true") {
              vm.VenueDrinkPgTable.tableData[index].MONITOR = true;
            } else if (
              vm.VenueDrinkPgTable.tableData[index].MONITOR == "false"
            ) {
              vm.VenueDrinkPgTable.tableData[index].MONITOR = false;
            }
          });
        }
      );
    },
    /*
      - Calls a GET API to get a list of customers from the simplica database.
      - fills the appropriate select lists with the retrieved data.
    */
    getCustomers() {
      let vm = this;
      this.Instance.get("simplica/new_drinks/customers").then(response => {
        vm.customers = response.data;
      });
    },
    /*
      - Calls a GET API to get a list of venues from the simplica database.
      - fills the appropriate select lists with the retrieved data.
    */
    getVenues() {
      this.Instance.get(
        "simplica/new_drinks/venues?customerId=" + this.customer
      ).then(response => {
        this.venues = response.data;
      });
      this.venue = "";
    },
    /*
      - Called when the user, clicks the "Save Updates" button in the UI.
      - Filters the VenueDrinkPgTable with the changed flag, to get only the drinks with the changed flag set as true.
      - Calls the simplica/updateDrinks/ POST API to update the cahnged drinks, in the backend.
    */
    updateDrinks() {
      let data = this.VenueDrinkPgTable.tableData.filter(item => {
        return item.changed === true;
      });

      let vm = this;
      data.forEach((element, index)=>{
        this.$set(
          data[index],
          "MODIFIED_BY",
          vm.$cookies.get("username")
        );
      });

      this.Instance.post("simplica/updateDrinks/", data)
        .then(function(response) {
          console.log(response);
          vm.$router.go();
        })
        .catch(function(error) {
          console.error(error);
        });
      this.changesMade = false;
    }
  },
  mounted() {
    this.getCustomers();
  },
  created() {
    // is called when the user tries to leave/close the page with usaved changes.
    window.addEventListener("beforeunload", this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  }
};
</script>
<style>
span.error {
  color: #e74c3c;
  font-size: 20px;
  display: flex;
  justify-content: right;
}
</style>
